.presets-container {
	 display: flex;
	 list-style: none;
	 margin: 0 10px;
	 padding: 10px 0 15px;
}
 .presets-container > li {
	 margin: 0 10px 0 0;
}
 .presets-container > li .selected-preset {
	 background-color: #0078d4;
	 border: 1px solid #0078d4;
	 color: white;
}

.DateInput {
  /* width: auto !important; */
  height: auto;
  line-height: 56px;
  background-color: transparent !important;
}
.DateRangePickerInput {
  border: none !important;
  background-color: transparent !important;
}
.DateRangePicker_picker {
  z-index: 3 !important;
}